import React from 'react';
import { Box } from '@deposits/ui-kit-react';
import './LogoComponent.scss';

const LogoComponent = (props) => {
  return (
    <Box
      is="img"
      src={props.src}
      alt="Logo"
      height={props.height ?? '100%'}
      width={props.width ?? '50px'}
    />
  );
};

export default LogoComponent;
