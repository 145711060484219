import { Box } from '@deposits/ui-kit-react';
import '../../sass/components/_firstColumnAd.scss';
import ad from '../../images/vectors/advert.svg';

const FirstColumnAd = () => {
  return (
    <Box className="firstColumnAd">
      <img
        className="firstColumnAd__Image"
        onClick={() => window.open('https://smartai.studio/')}
        src={ad}
        alt="ad"
      />
    </Box>
  );
};

export default FirstColumnAd;
