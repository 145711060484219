import React, { useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';
import HeadlineCards from '../components/HeadlineCard/HeadlineCards';
import usePostQueryStore from '../store';
import './NewsFeedLayout.scss';
import { IoIosArrowDown } from 'react-icons/io';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  getBusinessPosts,
  getEnvironmentPosts,
  getGovernmentPosts,
  getHealthPosts,
  getSciencePosts,
  getTechnologyPosts,
  getWorldPosts,
} from '../services/apiCategories';

const NewsFeedLayout = ({ ProfileSection, WatchingSection, firstColumnAd }) => {
  const [anchorElLeft, setAnchorElLeft] = useState(null);
  const [anchorElRight, setAnchorElRight] = useState(null);

  const [anchorTopicLeft, setAnchorTopicLeft] = useState(null);
  const [anchorTopicRight, setAnchorTopicRight] = useState(null);

  const [leftTopics, setLeftTopics] = useState([]);
  const [rightTopics, setRightTopics] = useState([]);
  const [loadingTopicsLeft, setLoadingTopicsLeft] = useState(true);
  const [loadingTopicsRight, setLoadingTopicsRight] = useState(true);

  const setActiveCategoryLeft = usePostQueryStore(
    (state) => state.setActiveCategoryLeft,
  );
  const setActiveCategoryRight = usePostQueryStore(
    (state) => state.setActiveCategoryRight,
  );
  const activeCategoryLeft = usePostQueryStore(
    (state) => state.activeCategoryLeft,
  );
  const activeCategoryRight = usePostQueryStore(
    (state) => state.activeCategoryRight,
  );

  const newsFeedStyle = usePostQueryStore((state) => state.newsFeedStyle);

  const setActiveTopicLeft = usePostQueryStore(
    (state) => state.setActiveTopicLeft,
  );

  const setActiveTopicRight = usePostQueryStore(
    (state) => state.setActiveTopicRight,
  );

  const activeTopicLeft = usePostQueryStore((state) => state.activeTopicLeft);

  const activeTopicRight = usePostQueryStore((state) => state.activeTopicRight);

  const handleClose = () => {
    setAnchorElLeft(null);
    setAnchorElRight(null);
  };

  const handleTopicMenuClose = () => {
    setAnchorTopicLeft(null);
    setAnchorTopicRight(null);
  };

  const handleButtonClick = (event, side) => {
    const setActiveCategory =
      side === 'left' ? setActiveCategoryLeft : setActiveCategoryRight;
    const setAnchorEl = side === 'left' ? setAnchorElLeft : setAnchorElRight;
    setActiveCategory(event.target.innerText.toLowerCase());
    setAnchorEl(null);
  };

  const handleClick = (event, side) => {
    const setAnchorEl = side === 'left' ? setAnchorElLeft : setAnchorElRight;
    setAnchorEl(event.currentTarget);
  };

  const handleTopicClick = (event, side) => {
    const setActiveTopic =
      side === 'left' ? setActiveTopicLeft : setActiveTopicRight;
    const setAnchorTopic =
      side === 'left' ? setAnchorTopicLeft : setAnchorTopicRight;
    setActiveTopic(event.target.innerText.toLowerCase());
    setAnchorTopic(null);
  };

  const handleTopicButtonClick = (event, side) => {
    const setAnchorTopic =
      side === 'left' ? setAnchorTopicLeft : setAnchorTopicRight;
    setAnchorTopic(event.currentTarget);
  };

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        setLoadingTopicsLeft(true);
        if (!activeCategoryLeft) return;

        const categoryApiMap = {
          business: getBusinessPosts,
          world: getWorldPosts,
          science: getSciencePosts,
          health: getHealthPosts,
          government: getGovernmentPosts,
          environment: getEnvironmentPosts,
          technology: getTechnologyPosts,
        };
        const apiFunction = categoryApiMap[activeCategoryLeft];
        const data = await apiFunction();
        const uniqueTopics = new Set();
        data.forEach((post) => {
          post.topics.forEach((topic) => {
            uniqueTopics.add(topic);
          });
        });
        setLeftTopics(Array.from(uniqueTopics));
      } catch (error) {
        console.error(`Error fetching topics:`, error);
      } finally {
        setLoadingTopicsLeft(false);
      }
    };

    fetchTopics();
  }, [activeCategoryLeft]);

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        setLoadingTopicsRight(true);
        if (!activeCategoryRight) return;

        const categoryApiMap = {
          business: getBusinessPosts,
          world: getWorldPosts,
          science: getSciencePosts,
          health: getHealthPosts,
          government: getGovernmentPosts,
          environment: getEnvironmentPosts,
          technology: getTechnologyPosts,
        };
        const apiFunction = categoryApiMap[activeCategoryRight];
        const data = await apiFunction();
        const uniqueTopics = new Set();
        data.forEach((post) => {
          post.topics.forEach((topic) => {
            uniqueTopics.add(topic);
          });
        });
        setRightTopics(Array.from(uniqueTopics));
      } catch (error) {
        console.error(`Error fetching topics:`, error);
      } finally {
        setLoadingTopicsRight(false);
      }
    };

    fetchTopics();
  }, [activeCategoryRight]);

  return (
    <div className="mainColumnLayout">
      <div style={{ height: '100%' }} className="column__layout">
        <div className="first__column__section p-1">
          {ProfileSection}
          {WatchingSection}
          {firstColumnAd}
        </div>

        <div className="second__column__section p-3">
          {newsFeedStyle === 'double' && (
            <div className="main">
              <div className="main__container left">
                <div className="main__container__buttonWrapper">
                  <div>
                    <button
                      aria-controls="left-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleClick(e, 'left')}
                      className="main__container__buttonWrapper__button main__container__buttonWrapper__buttonCategory"
                    >
                      <p className="main__container__buttonWrapper__buttonText ">
                        {activeCategoryLeft}
                      </p>
                      <IoIosArrowDown className="main__container__buttonWrapper__icon" />
                    </button>
                    <Menu
                      id="left-menu"
                      anchorEl={anchorElLeft}
                      keepMounted
                      open={Boolean(anchorElLeft)}
                      onClose={handleClose}
                      style={{ marginTop: '43px' }}
                    >
                      {[
                        'Science',
                        'Technology',
                        'Environment',
                        'Government',
                        'Business',
                        'Health',
                        'World',
                      ].map((category, index) => (
                        <MenuItem
                          key={index}
                          onClick={(e) => handleButtonClick(e, 'left')}
                        >
                          {category}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>

                  <div>
                    <button
                      aria-controls="left-topic-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleTopicButtonClick(e, 'left')}
                      className="main__container__buttonWrapper__button main__container__buttonWrapper__buttonTopic"
                    >
                      <p className="main__container__buttonWrapper__buttonText">
                        {activeTopicLeft}
                      </p>
                      <IoIosArrowDown className="main__container__buttonWrapper__icon" />
                    </button>

                    <Menu
                      id="left-topic-menu"
                      anchorEl={anchorTopicLeft}
                      keepMounted
                      open={Boolean(anchorTopicLeft)}
                      onClose={handleTopicMenuClose}
                      style={{ marginTop: '43px' }}
                    >
                      {leftTopics.length > 0 &&
                        leftTopics.map((topic, index) => (
                          <MenuItem
                            key={index}
                            onClick={(e) => handleTopicClick(e, 'left')}
                          >
                            {topic}
                          </MenuItem>
                        ))}
                    </Menu>
                  </div>
                </div>
                <div className="main__container__contentWrapper left">
                  <HeadlineCards
                    activeCategory={activeCategoryLeft}
                    activeTopic={activeTopicLeft}
                  />
                </div>
              </div>
              <div className="main__container right">
                <div className="main__container__buttonWrapper">
                  <div>
                    <button
                      aria-controls="right-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleClick(e, 'right')}
                      className="main__container__buttonWrapper__button main__container__buttonWrapper__buttonCategory"
                    >
                      <p className="main__container__buttonWrapper__buttonText ">
                        {activeCategoryRight}
                      </p>
                      <IoIosArrowDown className="main__container__buttonWrapper__icon" />
                    </button>
                    <Menu
                      id="right-menu"
                      anchorEl={anchorElRight}
                      keepMounted
                      open={Boolean(anchorElRight)}
                      onClose={handleClose}
                      style={{ marginTop: '43px' }}
                    >
                      {[
                        'Science',
                        'Technology',
                        'Environment',
                        'Government',
                        'Business',
                        'Health',
                        'World',
                      ].map((category, index) => (
                        <MenuItem
                          key={index}
                          onClick={(e) => handleButtonClick(e, 'right')}
                        >
                          {category}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>

                  <div>
                    <button
                      aria-controls="right-topic-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleTopicButtonClick(e, 'right')}
                      className="main__container__buttonWrapper__button main__container__buttonWrapper__buttonTopic"
                    >
                      <p className="main__container__buttonWrapper__buttonText">
                        {activeTopicRight}
                      </p>
                      <IoIosArrowDown className="main__container__buttonWrapper__icon" />
                    </button>

                    <Menu
                      id="right-topic-menu"
                      anchorEl={anchorTopicRight}
                      keepMounted
                      open={Boolean(anchorTopicRight)}
                      onClose={handleTopicMenuClose}
                      style={{ marginTop: '43px' }}
                    >
                      {rightTopics.length > 0 &&
                        rightTopics.map((topic, index) => (
                          <MenuItem
                            key={index}
                            onClick={(e) => handleTopicClick(e, 'right')}
                          >
                            {topic}
                          </MenuItem>
                        ))}
                    </Menu>
                  </div>
                </div>

                <div className="main__container__contentWrapper right">
                  <HeadlineCards
                    activeCategory={activeCategoryRight}
                    activeTopic={activeTopicRight}
                  />
                </div>
              </div>
            </div>
          )}
          {newsFeedStyle === 'single' && (
            <div className="main">
              <div className="main__container main__container--full left">
                <div className="main__container__buttonWrapper">
                  <div>
                    <button
                      aria-controls="left-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleClick(e, 'left')}
                      className="main__container__buttonWrapper__button main__container__buttonWrapper__buttonCategory"
                    >
                      <p className="main__container__buttonWrapper__buttonText ">
                        {activeCategoryLeft}
                      </p>
                      <IoIosArrowDown className="main__container__buttonWrapper__icon" />
                    </button>
                    <Menu
                      id="left-menu"
                      anchorEl={anchorElLeft}
                      keepMounted
                      open={Boolean(anchorElLeft)}
                      onClose={handleClose}
                      style={{ marginTop: '43px' }}
                    >
                      {[
                        'Science',
                        'Technology',
                        'Environment',
                        'Government',
                        'Business',
                        'Health',
                        'World',
                      ].map((category, index) => (
                        <MenuItem
                          key={index}
                          onClick={(e) => handleButtonClick(e, 'left')}
                        >
                          {category}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>

                  <div>
                    <button
                      aria-controls="left-topic-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleTopicButtonClick(e, 'left')}
                      className="main__container__buttonWrapper__button main__container__buttonWrapper__buttonTopic"
                    >
                      <p className="main__container__buttonWrapper__buttonText">
                        {activeTopicLeft}
                      </p>
                      <IoIosArrowDown className="main__container__buttonWrapper__icon" />
                    </button>

                    <Menu
                      id="left-topic-menu"
                      anchorEl={anchorTopicLeft}
                      keepMounted
                      open={Boolean(anchorTopicLeft)}
                      onClose={handleTopicMenuClose}
                      style={{ marginTop: '43px' }}
                    >
                      {leftTopics.length > 0 &&
                        leftTopics.map((topic, index) => (
                          <MenuItem
                            key={index}
                            onClick={(e) => handleTopicClick(e, 'left')}
                          >
                            {topic}
                          </MenuItem>
                        ))}
                    </Menu>
                  </div>
                </div>
                <div className="main__container__contentWrapper main__container__contentWrapper--full left">
                  <HeadlineCards
                    activeCategory={activeCategoryLeft}
                    activeTopic={activeTopicLeft}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsFeedLayout;
