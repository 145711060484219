import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import Homepage from '../HomePage/HomePage';
import HeroBlogSingle from '../HeroBlogSingle/HeroBlogSingle';
import PostDetailsPage from '../PostPage/PostDetailsPage';
import ErrorPage from '../ErrorPage/ErrorPage';
//import LoginPage from '../LoginPage';
//import SignUpPage from '../SignUpPage';
import ForgotPassword from '../ForgotPassword';
import BreakingSingle from '../BreakingSingle/BreakingSingle';
import SponsoredSingle from '../SponsoredSingle/SponsoredSingle';
import FinanceBlogPage from '../FinanceBlogPage/FinanceBlogPage';
import WorldBlogPage from '../WorldBlogPage/WorldBlogPage';
import ScienceBlogPage from '../ScienceBlogPage/ScienceBlogPage';
import GovernmentBlogPage from '../GovernmentBlogPage/GovernmentBlogPage';
import EnvironmentBlogPage from '../EnvironmentBlogPage/EnvironmentBlogPage';
import TechnologyBlogPage from '../TechnologyBlogPage/TechnologyBlogPage';
import PostDetails from '../../components/PostDetails/PostDetails';
import BookmarkDetailsPage from '../BookmarkPage/BookmarkDetailsPage';
import SearchPage2 from '../../components/Search/SearchPage2';
import SearchPage from '../../components/Search/SearchPage';

import { ColorModeProvider } from '../../context/ColorModeContext';
import HealthBlogPage from '../HealthBlogPage/HealthBlogPage';
import FAQ from '../../components/FAQS/FAQ';
import HomeTopics from '../HomePage/HomeTopics';

//import ProfileDetailsPage from '../ProfilePage/ProfileDetailsPage';
//import ProfileSettings from '../ProfilePage/ProfileSettings';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // staleTime: 60 * 1000,
      staleTime: 0,
    },
  },
});

const AllRoute = () => {
  //<Route path="profile" element={<ProfileDetailsPage />} />
  //<Route path="settings" element={<ProfileSettings />} />        
  //<Route path="login" element={<LoginPage />} />
  //<Route path="register" element={<SignUpPage />} />    

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />

        <ColorModeProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="home" element={<Homepage />} />
              <Route path="topics" element={<HomeTopics />} />
              <Route path="faq" element={<FAQ />} />
              <Route
                path="hero-blog-single/:slug"
                element={<HeroBlogSingle />}
              />
              <Route
                path="breaking-single/:slug"
                element={<BreakingSingle />}
              />
              <Route
                path="sponsor-single/:slug"
                element={<SponsoredSingle />}
              />

              <Route path="health" element={<HealthBlogPage />} />
              <Route path="business" element={<FinanceBlogPage />} />
              <Route path="world" element={<WorldBlogPage />} />
              <Route path="science" element={<ScienceBlogPage />} />
              <Route path="environment" element={<EnvironmentBlogPage />} />
              <Route path="technology" element={<TechnologyBlogPage />} />
              <Route path="government" element={<GovernmentBlogPage />} />
              <Route
                path="post-details-page/:id"
                element={<PostDetailsPage />}
              />
              <Route path="bookmarks" element={<BookmarkDetailsPage />} />
              <Route path="post-details/:postId" element={<PostDetails />} />
              <Route path="search" element={<SearchPage />}/>
             {/* <Route path="search/:q" element={<SearchPage2 />}/>*/}
                
              


              {/* <Route path="/headline-card" element={<HeadlineCard />} /> */}
              <Route path="404" element={<ErrorPage />} />
              <Route path="/post-details/:postId" element={<PostDetails />} />

              <Route path="forgot-password" element={<ForgotPassword />} />
            </Routes>
          </BrowserRouter>
        </ColorModeProvider>
      </QueryClientProvider>
    </div>
  );
};

export default AllRoute;
