import { Box, Text, TextField, SearchIcon } from '@deposits/ui-kit-react';
import { Link, useNavigate } from 'react-router-dom';
import profileIcon from '../../Icons/profileIcon.svg';
import LogoComponent from './LogoComponent';
import greenLogoIcon from '../../Icons/greenLogoIcon.svg';
import purpleLogoIcon from '../../Icons/purpleLogoIcon.svg';
import { Menu } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import usePostQueryStore from '../../store';
import '../../sass/components/_blog-header.scss';
import '../../sass/components/_mobileMenu.scss';
import './BlogHeader.scss';
import MobileMenu from '../MobileMenu/MobileMenu';
import { useColorMode } from '../../context/ColorModeContext';
import {
  getBusinessPosts,
  getEnvironmentPosts,
  getGovernmentPosts,
  getHealthPosts,
  getSciencePosts,
  getTechnologyPosts,
  getWorldPosts,
} from '../../services/apiCategories';
import { FaDesktop } from 'react-icons/fa';
import { FaMobileAlt } from 'react-icons/fa';

const BlogHeader = (props) => {
  const ref = useRef(null);
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(true);
  const setSearchText = usePostQueryStore((s) => s.setSearchText);
  const setActiveCategoryLeft = usePostQueryStore(
    (state) => state.setActiveCategoryLeft,
  );

  const setActiveCategoryRight = usePostQueryStore(
    (state) => state.setActiveCategoryRight,
  );

  const activeCategoryLeft = usePostQueryStore(
    (state) => state.activeCategoryLeft,
  );

  const activeCategoryRight = usePostQueryStore(
    (state) => state.activeCategoryRight,
  );

  const setActiveTopicLeft = usePostQueryStore(
    (state) => state.setActiveTopicLeft,
  );

  const setActiveTopicRight = usePostQueryStore(
    (state) => state.setActiveTopicRight,
  );

  const newsFeedStyle = usePostQueryStore((state) => state.newsFeedStyle);

  const setNewsFeedStyle = usePostQueryStore((state) => state.setNewsFeedStyle);

  const activeTopicLeft = usePostQueryStore((state) => state.activeTopicLeft);

  const activeTopicRight = usePostQueryStore((state) => state.activeTopicRight);

  // const { isLoading, posts, error } = categoryHooks[activeCategoryLeft];

  const handleCategoryClick = (category) => {
    setActiveCategoryLeft(category);
    setActiveCategoryRight(category);
  };

  useEffect(() => {
    if (topics.length > 0) {
      setActiveTopicLeft(topics[0]);
      setActiveTopicRight(topics[0]);
    }
  }, [topics, setActiveTopicLeft, setActiveTopicRight]);

  const handleTopicClick = (topic) => {
    setActiveTopicLeft(topic);
    setActiveTopicRight(topic);
  };

  const navigate = useNavigate();
  const { colorMode, colorModes } = useColorMode();

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        setLoading(true);
        if (!activeCategoryLeft || !activeCategoryRight) return;

        const categoryApiMap = {
          business: getBusinessPosts,
          world: getWorldPosts,
          science: getSciencePosts,
          health: getHealthPosts,
          government: getGovernmentPosts,
          environment: getEnvironmentPosts,
          technology: getTechnologyPosts,
        };
        const apiFunction = categoryApiMap[activeCategoryLeft];
        const data = await apiFunction();
        const uniqueTopics = new Set();
        data.forEach((post) => {
          post.topics.forEach((topic) => {
            uniqueTopics.add(topic);
          });
        });
        setTopics(Array.from(uniqueTopics));
      } catch (error) {
        console.error(`Error fetching topics:`, error);
      } finally {
        setLoading(false);
      }
    };

    fetchTopics();
  }, [activeCategoryLeft, activeCategoryRight]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const searchTextValue = ref.current.value;
    setSearchText(searchTextValue);
    navigate('/search');
  };

  let userProfile = () => {
    <Box className="navbar__profile__section">
      <Box className="nav__bar__profile">
        <Box is="a" href="#">
          <Box is="img" src={profileIcon} width="30px" />
        </Box>
        <Box>
          <Text>
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Box className="header__text__font">Profile</Box>
              </Menu.Target>

              <Menu.Dropdown className="header__menu">
                <Link to="/profile">
                  <Menu.Item className="header__menu__drop">
                    My Profile
                  </Menu.Item>
                </Link>
                <Link to="/bookmarks">
                  <Menu.Item className="header__menu__drop">
                    <Link to="/bookmarks">Bookmarks</Link>
                  </Menu.Item>
                </Link>
                <Link to="/settings">
                  <Menu.Item className="header__menu__drop">
                    <Link to="/settings">Settings</Link>
                  </Menu.Item>
                </Link>
              </Menu.Dropdown>
            </Menu>
          </Text>
        </Box>
      </Box>
    </Box>;
  };

  return (
    <Box is="header" id="navbar">
      <Box className="navbar__section navbar__section--headerV2">
        <Box is={Link} className="logo" to="/">
          <LogoComponent
            src={`${
              colorMode === colorModes.green ? greenLogoIcon : purpleLogoIcon
            }`}
            width="80%"
          />
        </Box>

        <div className="navbar__section__menu">
          <ul className="categoriesBar">
            <li className="categoriesBar__item">
              <Link
                className={`categoriesBar__item__link ${
                  activeCategoryLeft === 'world' &&
                  activeCategoryRight === 'world'
                    ? 'categoriesBar__item__link--active'
                    : ''
                }`}
                onClick={() => handleCategoryClick('world')}
                to="/world"
              >
                World
              </Link>
            </li>
            <li className="categoriesBar__item">
              <Link
                className={`categoriesBar__item__link ${
                  activeCategoryLeft === 'government' &&
                  activeCategoryRight === 'government'
                    ? 'categoriesBar__item__link--active'
                    : ''
                }`}
                onClick={() => handleCategoryClick('government')}
                to="/government"
              >
                U.S.
              </Link>
            </li>
            <li className="categoriesBar__item">
              <Link
                className={`categoriesBar__item__link ${
                  activeCategoryLeft === 'health' &&
                  activeCategoryRight === 'health'
                    ? 'categoriesBar__item__link--active'
                    : ''
                }`}
                onClick={() => handleCategoryClick('health')}
                to="/health"
              >
                Health
              </Link>
            </li>
            <li className="categoriesBar__item">
              <Link
                className={`categoriesBar__item__link ${
                  activeCategoryLeft === 'science' &&
                  activeCategoryRight === 'science'
                    ? 'categoriesBar__item__link--active'
                    : ''
                }`}
                onClick={() => handleCategoryClick('science')}
                to="/science"
              >
                Science
              </Link>
            </li>
            <li className="categoriesBar__item">
              <Link
                className={`categoriesBar__item__link ${
                  activeCategoryLeft === 'environment' &&
                  activeCategoryRight === 'environment'
                    ? 'categoriesBar__item__link--active'
                    : ''
                }`}
                onClick={() => handleCategoryClick('environment')}
                to="/environment"
              >
                Environment
              </Link>
            </li>
            <li className="categoriesBar__item">
              <Link
                className={`categoriesBar__item__link ${
                  activeCategoryLeft === 'technology' &&
                  activeCategoryRight === 'technology'
                    ? 'categoriesBar__item__link--active'
                    : ''
                }`}
                onClick={() => handleCategoryClick('technology')}
                to="/technology"
              >
                Technology
              </Link>
            </li>
            <li className="categoriesBar__item">
              <Link
                className={`categoriesBar__item__link ${
                  activeCategoryLeft === 'business' &&
                  activeCategoryRight === 'business'
                    ? 'categoriesBar__item__link--active'
                    : ''
                }`}
                onClick={() => handleCategoryClick('business')}
                to="/business"
              >
                Business
              </Link>
            </li>
          </ul>

          {loading ? (
            <p className="topicsBar__text">Loading topics...</p>
          ) : (
            <ul className="topicsBar">
              {topics.map((topic) => (
                <span
                  onClick={() => handleTopicClick(topic)}
                  className={`topicsBar__text ${
                    activeTopicLeft === topic && activeTopicRight === topic
                      ? 'topicsBar__text--active'
                      : ''
                  }`}
                  key={topic}
                >
                  {topic}
                </span>
              ))}
            </ul>
          )}
        </div>

        <form className="navbar__section__form" onSubmit={handleFormSubmit}>
          <TextField
            ref={ref}
            size="medium"
            leftIcon={SearchIcon}
            placeholder="Search news"
          />
        </form>

        {newsFeedStyle === 'double' && (
          <div className="newsFeedStyleIconContainer">
            <FaMobileAlt
              onClick={() => setNewsFeedStyle('single')}
              className="newsFeedStyleIcon--mobile"
            />
          </div>
        )}
        {newsFeedStyle === 'single' && (
          <div className="newsFeedStyleIconContainer">
            <FaDesktop
              onClick={() => setNewsFeedStyle('double')}
              className="newsFeedStyleIcon--desktop"
            />
          </div>
        )}

        <div className={`wpo-site-header ${props.hclass}`}>
          <nav className="navigation navbar navbar-expand-lg navbar-light">
            <div className="mobail-menu menuToggle">
              <div className="mobail-menu">
                <MobileMenu />
              </div>
            </div>
          </nav>
        </div>
      </Box>
    </Box>
  );
};

export default BlogHeader;
