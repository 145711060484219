//const API_URL = process.env.REACT_APP_API_URL;
const API_URL = "https://localhost:32770";
// "https://smartai-news-api-dev-us-east1-vjq6bs7ara-ue.a.run.app"
//  https://localhost:49153";

export async function getPosts() {

  const res = await fetch(`${API_URL}/posts_bq?filter=""`);

  if (!res.ok) throw Error('Failed getting posts');

  const data = await res.json();
  return data;
}
export async function getSearchPosts(keyword) {
  // log the keyword to the console
  console.log('getSearchPosts keyword', JSON.stringify(keyword));

  const res = await fetch(`${API_URL}/posts/search?keyword=${keyword}`);

  if (!res.ok) throw Error('Failed getting posts');

  const data = await res.json();
  return data;
}
export async function getSinglePost(id) {
  const res = await fetch(`${API_URL}/posts/${id}`);

  if (!res.ok) throw Error('Failed getting post');

  const data = await res.json();
  return data;
}

// create a post API call to {API_URL}/subscribe with a payload of {email: email, keyword: 'daily'}
export async function subscribe(email) {
  var payload = JSON.stringify({ userId: email, keyword: 'daily' });

  console.log('subscribe payload', payload);
  const res = await fetch(`${API_URL}/posts/subscribe`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: payload,
  });

  if (!res.ok) throw Error('Failed subscribing');
  const data = await res.json();
  return data;
}
