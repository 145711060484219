import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import HeaderTopbar from '../HeaderTopbar/HeaderTopbar';
import MobileMenu from '../MobileMenu/MobileMenu';
// import logoIcon from '../../Icons/logoIcon.svg';
import greenLogoIcon from '../../Icons/greenLogoIcon.svg';
import purpleLogoIcon from '../../Icons/purpleLogoIcon.svg';
import LogoComponent from '../FeedHeader/LogoComponent';
import usePostQueryStore from '../../store';
import { Box } from '@deposits/ui-kit-react';
import greenprofileIcon from '../../Icons/greenProfileIcon.svg';
import purpleProfileIcon from '../../Icons/purpleProfileIcon.svg';

import { useColorMode } from '../../context/ColorModeContext';

const Header = (props) => {
  const [menuActive, setMenuState] = useState(false);
  const ref = useRef(null);
  const setSearchText = usePostQueryStore((s) => s.setSearchText);
  const setActiveCategoryLeft = usePostQueryStore(
    (state) => state.setActiveCategoryLeft,
  );

  const setActiveCategoryRight = usePostQueryStore(
    (state) => state.setActiveCategoryRight,
  );
  const navigate = useNavigate();
  const { colorMode, toggleColorMode, colorModes } = useColorMode();

  const handleCategoryClick = (category) => {
    setActiveCategoryLeft(category);
    setActiveCategoryRight(category);
    // window.scrollTo(10, 0);
  };

  /*
 <li>
                      <Link onClick={ClickHandler} to="/bookmarks">
                        Bookmarks
                      </Link>
                    </li>
*/
  return (
    <header id="header" className={props.topbarNone}>
      {/* <HeaderTopbar /> */}
      <div className={`wpo-site-header ${props.hclass}`}>
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container">
            <div
              className="row align-items-center"
              style={{ flexWrap: 'nowrap' }}
            >
              <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                <div className="mobail-menu">
                  <MobileMenu />
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-6">
                <div className="navbar-header">
                  <Link className="navbar-brand" to="/home">
                    <LogoComponent
                      src={`${
                        colorMode === colorModes.green
                          ? greenLogoIcon
                          : purpleLogoIcon
                      }`}
                      width="100%"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-8 col-md-1 col-1">
                <div
                  id="navbar"
                  className="collapse navbar-collapse navigation-holder"
                >
                  <button className="menu-close">
                    <i className="ti-close"></i>
                  </button>
                  <ul className="nav navbar-nav mb-2 mb-lg-0">
                    {/* <li>
                      <Link onClick={ClickHandler} to="/">
                        Home
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        onClick={() => handleCategoryClick('world')}
                        to="/world"
                      >
                        World
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => handleCategoryClick('government')}
                        to="/government"
                      >
                        U.S.
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => handleCategoryClick('health')}
                        to="/health"
                      >
                        Health
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => handleCategoryClick('science')}
                        to="/science"
                      >
                        Science
                      </Link>
                    </li>

                    <li>
                      <Link
                        onClick={() => handleCategoryClick('environment')}
                        to="/environment"
                      >
                        Environment
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => handleCategoryClick('technology')}
                        to="/technology"
                      >
                        Technology
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => handleCategoryClick('business')}
                        to="/business"
                      >
                        Business
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-2">
                <div className="header-right" style={{ paddingRight: '10px' }}>
                  <div className="header-search-form-wrapper">
                    <div className="cart-search-contact">
                      <button
                        onClick={() => setMenuState(!menuActive)}
                        className="search-toggle-btn"
                      >
                        <i
                          className={`fi ti-search ${
                            menuActive ? 'ti-close' : 'fi '
                          }`}
                        ></i>
                      </button>
                      <div
                        className={`header-search-form ${
                          menuActive ? 'header-search-content-toggle' : ''
                        }`}
                      >
                        <form
                          onSubmit={(event) => {
                            event.preventDefault();
                            if (ref.current) {
                              setSearchText(ref.current.value);
                              navigate('/search');
                              ref.current.value = '';
                              setMenuState(!menuActive);
                            }
                          }}
                        >
                          <div>
                            <input
                              ref={ref}
                              type="text"
                              className="form-control"
                              placeholder="Search here..."
                            />
                            <button type="submit">
                              <i className="fi flaticon-magnifiying-glass"></i>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                is="a"
                href="#"
                className="col-lg-2 col-md-2 col-2"
                style={{ cursor: 'pointer' }}
                onClick={toggleColorMode}
              >
                <Box
                  is="img"
                  src={`${
                    colorMode === colorModes.green
                      ? greenprofileIcon
                      : purpleProfileIcon
                  }`}
                  width="30px"
                />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
