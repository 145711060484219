import { Box } from '@deposits/ui-kit-react';
import { Link } from 'react-router-dom';
import likeIcon from '../../images/vectors/like.svg';
import dislikeIcon from '../../images/vectors/dislike.svg';
import helpIcon from '../../images/vectors/question.svg';
import robotOne from '../../images/vectors/robot-student-elementary-school.svg';
import robotTwo from '../../images/vectors/robot-student-middle-school.svg';
import robotThree from '../../images/vectors/robot-student-high-school.svg';
import robotFour from '../../images/vectors/robot-student-college 3.svg';
import {
  cleanSummary,
  daysAgo,
  getCategoryAndTopicsString,
  getSummaryList,
  truncateString,
} from '../../services/utilsStringFuncs';
import './infoCards.scss';

export function postDetails(post) {
  return (
    <Box>
      <p className="headlineBox__body__details">
        {post.publisher.publisherName} | {post.channel.channelId}
      </p>
      <div className="headlineBox__body__wrapper">
        <p className="headlineBox__body__days">
          {daysAgo(post.publisher.publishDateString)}
        </p>
        <div className="headlineBox__body__likeWrapper">
          <div className="headlineBox__body__iconWrapper">
            <img
              className="headlineBox__body__iconWrapper__icon"
              src={likeIcon}
              alt="like icon"
            />
            <p className="headlineBox__body__iconWrapper__text">
              {post.metadata.likes}
            </p>
          </div>
          <div className="headlineBox__body__iconWrapper">
            <img
              className="headlineBox__body__iconWrapper__icon"
              src={dislikeIcon}
              alt="dislike icon"
            />
            <p className="headlineBox__body__iconWrapper__text">
              {post.metadata.dislikes}
            </p>
          </div>
        </div>
      </div>
    </Box>
  );
}

export function showPostBody(post) {
  if (!post) return null;
  // detect if mobile and return different layout
  const isMobile = window.innerWidth <= 500;
  //console.log("showPostBody - mobile", window.innerWidth, isMobile);

  var bodyHeight = '150px';

  return (
    <Box height={bodyHeight}>
      <Link to={post.url} title={post.title}>
        <p className="headlineBox__body__heading">
          {truncateString(post.title, isMobile === true ? 50 : 100)}
        </p>
      </Link>
      {postDetails(post)}
    </Box>
  );
}

export function NewAIGeneratedSection(post) {
  if (!post) return null;

  var summary = cleanSummary(post, false);
  if (summary.length > 100) {
    summary = 'AI SUMMARY:  ' + summary.substring(0, 100) + '...';
  }

  if (summary.length < 10) {
    summary = 'No AI summary available';
  }

  var summaryList = getSummaryList(post);

  return (
    <div>
      {summaryList.map((s, index) => (
        <div>
          <Box
            title={
              index === 0
                ? `${cleanSummary(post)}`
                : getCategoryAndTopicsString(post)
            }
          />
          <div>
            <span className="headlineBox__body__AIContainer__title">
              {s.title}
            </span>{' '}
            -{' '}
            <span className="headlineBox__body__AIContainer__note">
              {s.note}
            </span>
          </div>

          <p className="headlineBox__body__AIContainer__content">
            {s.AIsummary}
          </p>

          <div className="headlineBox__body__AIContainer__wrapper">
            <div className="headlineBox__body__AIContainer__veracityWrapper">
              <div className="headlineBox__body__AIContainer__helpWrapper">
                <img
                  className="headlineBox__body__AIContainer__helpImage"
                  src={helpIcon}
                  alt="help icon"
                />
                <p className="headlineBox__body__AIContainer__veracityText">
                  Veracity Score:{' '}
                  <span className="headlineBox__body__AIContainer__veracityTextSpan">
                    90%
                  </span>
                </p>
              </div>
              <p className="headlineBox__body__AIContainer__veracityText">
                Lean:{' '}
                <span className="headlineBox__body__AIContainer__veracityTextSpan">
                  Left, Center, Right
                </span>
              </p>
            </div>

            {s.AIsummary !== '' && (
              <div className="headlineBox__body__AIContainer__robotWrapper">
                <img
                  className="headlineBox__body__AIContainer__robotIconOne"
                  src={robotOne}
                  alt="ai voice"
                />
                <img
                  className="headlineBox__body__AIContainer__robotIconTwo"
                  src={robotTwo}
                  alt="ai voice"
                />
                <img
                  className="headlineBox__body__AIContainer__robotIconThree"
                  src={robotThree}
                  alt="ai voice"
                />
                <img
                  className="headlineBox__body__AIContainer__robotIconFour"
                  src={robotFour}
                  alt="ai voice"
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
