import React from "react";
import { Link } from "react-router-dom";
import { Box, Text } from "@deposits/ui-kit-react";
import { usePosts } from "../../hooks/usePosts";
import { useState } from "react";

import {
  AIGeneratedSection,
  cleanSummary,
  getChannelString,
  daysAgo,
  truncateString,
  renderMetadata
} from "../../services/utilsStringFuncs";

import Button from "@material-ui/core/Button";
import Ticker from "../../components/TickerWidget/TickerReact";
import { randomizeByCategory } from "../../services/utilsStringFuncs";


const handleCategoryClick = (category) => () => {
  window.location.href = `/${category.toLowerCase()}`;
};

const channelSection = (post) => {
  return (
    <Box
      style={{
        padding: "0.8rem 0",
      }}
    >
      <Text
        style={{
          fontSize: "0.8rem",
          fontStyle: "italic",
          color: "var(--color-text)",
        }}
      >
          {post.publisher.publisherName} | {post.channel.channelId}
            {//getChannelString(post.channel.channelId)
            }
      </Text>
      <Text
        style={{
          fontSize: "0.8rem",
          fontStyle: "italic",
          color: "var(--color-text)",
        }}
      >
        {daysAgo(post.publisher.publishDateString)}
      </Text>
    </Box>
  );
};

const renderPosts = (posts, toggleMetadata) => {
  return posts.map((post, index) => (
    <Box 
      className="col col-xl-3 col-lg-6 col-md-6 col-12" 
      key={index}
      style={{
        padding: "0.5rem",
      }}
    >

      <div
        className="wpo-blog-item"
        style={{
          border: "1px solid var(--color-border)",
          borderRadius: "15px",
          margin: "0.5rem",
          backgroundColor: "#f5f5f5",
          boxShadow: "1px 1px 3px 1px var(--color-border)",
        }}
      >
        <div className="wpo-blog-img" 
          style={{ 
            overflow: "hidden",
            
          }}
        
        >
          <img title={`${cleanSummary(post)}`} src={post.imageUrl} alt="" />
          <div style={{ 
            borderRadius: "25px",
          }}>
            <Button
              className="thumb"
              variant="contained"
              style={{
                borderRadius: "25px",
                fontSize: "1.0rem",                
              }}
              onClick={handleCategoryClick(post.category)}
            >
              {post.category}
            </Button>
          </div>
        </div>

        <Box
          className="wpo-blog-content"
          title={renderMetadata(post, false)}
          style={{
            variant: "contained",
            padding: "1rem",
            height: "160px",
            fontSize: "1.0rem",
            fontWeight: "bold",
            color: "var(--color-text)",
          }}
        >
          <Link title={post.title} to={post.url}>
            {truncateString(post.title, 60)}
          </Link>

          {channelSection(post)}
        </Box>
        {AIGeneratedSection(post)}
      </div>
    </Box>
  ));
};

const TrendingNews = () => {
  const { isLoading, posts } = usePosts();

  if (isLoading) return null;

  // establish different post arrays
  const randomPosts = [...posts].sort(() => Math.random() - Math.random());
  const sortedPosts = [...posts].sort((a, b) => {
    return new Date(b.publisher.publishDate) - new Date(a.publisher.publishDate);
  });

  // remove future posts from sorted posts 
  const now = new Date();

  // add a day to now
  now.setDate(now.getDate() + 1);
  
  const filteredPosts = sortedPosts.filter((post) => {
    return new Date(post.publisher.publishDate) <= now;
  });

  var randomizeRecentPosts = randomizeByCategory(filteredPosts,8);


  // partition posts into different arrays
  const recentTrendingNews = randomizeRecentPosts.slice(0, 4);
  const randomNews = filteredPosts.slice(0, 4);

  return (
    <section className="wpo-blog-sponsored-section">
      <div className="container">
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "1.5rem",
            paddingBottom: "1.5rem",
            minWidth: "90%",
          }}
        >
          <Ticker posts={randomPosts} maxLength="100" />
        </Box>

        <div className="wpo-section-title">
          <h2>Discover</h2>
        </div>
        <div className="row">
          <div className="wpo-blog-sponsored-wrap">
            <div className="wpo-blog-items">
              <div className="row">
                {renderPosts(recentTrendingNews, false)}
                {renderPosts(randomNews, false)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrendingNews;
