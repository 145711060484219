import React, { useState } from 'react';
import { useScience } from '../../main-component/ScienceBlogPage/useScience';
import getImageUrl from '../NoImage/NoImage';
import SpinnerMini from '../Spinner/SpinnerMini';
import { cleanSummary } from '../../services/utilsStringFuncs';
// import { filterFuturePosts } from '../../services/utilsStringFuncs';

import comment from '../../images/vectors/comment.svg';
import share from '../../images/vectors/share.svg';
import avatar from '../../images/vectors/userAvatar.svg';
import loveIcon from '../../images/vectors/love.svg';
import bookmark from '../../images/vectors/bookmark.svg';
import { MdFormatBold } from 'react-icons/md';
import { MdFormatItalic } from 'react-icons/md';
import { MdFormatUnderlined } from 'react-icons/md';
import { MdFormatListBulleted } from 'react-icons/md';
import { FaLink } from 'react-icons/fa6';
import { IoIosArrowDown } from 'react-icons/io';
import { MdMoreHoriz } from 'react-icons/md';
import './infoCards.scss';
import { NewAIGeneratedSection, showPostBody } from './utilfunctions';
import { useBusiness } from '../../main-component/FinanceBlogPage/useBusiness';
import { useTechnology } from '../../main-component/TechnologyBlogPage/useTechnology';
import { useWorld } from '../../main-component/WorldBlogPage/useWorld';
import { useGovernment } from '../../main-component/GovernmentBlogPage/useGovernment';
import { useHealth } from '../../main-component/HealthBlogPage/useHealth';
import { useEnvironment } from '../../main-component/EnvironmentBlogPage/useEnvironment';
import { useColorMode } from '../../context/ColorModeContext';
import '../../layouts/NewsFeedLayout.scss';

const HeadlineCards = ({ activeCategory, activeTopic }) => {
  const categoryHooks = {
    science: useScience(),
    business: useBusiness(),
    technology: useTechnology(),
    world: useWorld(),
    government: useGovernment(),
    health: useHealth(),
    environment: useEnvironment(),
  };

  const [commentsDisplayed, setCommentsDisplayed] = useState({});
  const { colorMode, colorModes } = useColorMode();

  // const activeCategory = usePostQueryStore((state) => state.activeCategory);

  const { isLoading, posts, error } = categoryHooks[activeCategory];

  const toggleCommentsDisplayed = (postId) => {
    setCommentsDisplayed((prev) => ({
      ...prev,
      [postId]: !prev[postId],
    }));
  };

  if (isLoading) return <SpinnerMini />;
  if (error) return <div>An error occurred</div>;

  // const filteredPosts = filterFuturePosts(
  //   posts,
  //   activeCategory[0].toUpperCase() + activeCategory.slice(1),
  // );

  // console.log('filtered posts', filteredPosts);

  const filteredPostsByTopics = posts.filter((post) =>
    post.topics.includes(activeTopic),
  );

  // const containerHeight = filteredPostsByTopics.length === 1 ? '50vh' : '100vh';

  return (
    <>
      {/* <div style={{ height: containerHeight }}> */}
      {filteredPostsByTopics.length > 0 ? (
        <>
          {filteredPostsByTopics.map((post, index) => (
            <div className="headlineBox" key={index}>
              <div
                className="headlineBox__container"
                title={cleanSummary(post)}
              >
                <div className="headlineBox__wrapper">
                  <div className="headlineBox__wrapper__tag">
                    #artemis program
                  </div>
                  <div className="headlineBox__wrapper__tag">#nasa</div>
                  <div className="headlineBox__wrapper__tag">
                    #space launch system
                  </div>
                  <div className="headlineBox__wrapper__tag">
                    #space exploration
                  </div>
                </div>
                <img
                  className="img img-responsive headlineBox__image"
                  src={getImageUrl(post.imageUrl)}
                  alt="post"
                  width="100%"
                  onClick={() => window.open(post.url, '_blank')}
                />

                <div className="headlineBox__body">
                  <div className="headlineBox__body__content">
                    {showPostBody(post)}
                  </div>
                  <div
                    className={`headlineBox__body__AIContainer ${
                      colorMode === colorModes.green ? 'green' : 'purple'
                    }`}
                  >
                    {NewAIGeneratedSection(post)}
                  </div>
                  <div className="headlineBox__body__actions">
                    <div className="headlineBox__body__actions__wrapper">
                      <div
                        className="headlineBox__body__actions__wrapper__box"
                        onClick={() => toggleCommentsDisplayed(post.id)}
                      >
                        <img
                          className="headlineBox__body__actions__wrapper__boxImage"
                          src={comment}
                          alt="comment icon"
                        />
                        <p className="headlineBox__body__actions__wrapper__boxText">
                          50 Comments
                        </p>
                      </div>
                      <div className="headlineBox__body__actions__wrapper__box">
                        <img
                          className="headlineBox__body__actions__wrapper__boxImage"
                          src={share}
                          alt="share icon"
                        />
                        <p className="headlineBox__body__actions__wrapper__boxText">
                          Share
                        </p>
                      </div>
                    </div>
                    <div className="headlineBox__body__actions__wrapper__box">
                      <img
                        className="headlineBox__body__actions__wrapper__boxImage"
                        src={bookmark}
                        alt="bookmark icon"
                      />
                      <p className="headlineBox__body__actions__wrapper__boxText">
                        Bookmark
                      </p>
                    </div>
                  </div>
                  {commentsDisplayed[post.id] && (
                    <div className="headlineBox__body__commentSection">
                      <form className="headlineBox__body__form">
                        <input
                          className="headlineBox__body__form__input"
                          placeholder="Write a comment..."
                        />
                        <div className="headlineBox__body__form__actions">
                          <div className="headlineBox__body__form__iconWrapper">
                            <MdFormatBold className="headlineBox__body__form__icon" />
                            <MdFormatItalic className="headlineBox__body__form__icon" />
                            <MdFormatUnderlined className="headlineBox__body__form__icon" />
                            <MdFormatListBulleted className="headlineBox__body__form__icon" />
                            <FaLink className="headlineBox__body__form__icon" />
                          </div>
                          <button className="headlineBox__body__form__button">
                            Comment
                          </button>
                        </div>
                      </form>
                      <div className="headlineBox__body__commentActions">
                        <div className="headlineBox__body__commentActionsWrapper">
                          <p className="headlineBox__body__commentRelevant">
                            Most Relevant
                          </p>
                          <IoIosArrowDown className="headlineBox__body__commentRelevantIcon" />
                        </div>
                        <p className="headlineBox__body__commentShowMore">
                          View More Comments
                        </p>
                      </div>

                      <div className="headlineBox__body__commentBox">
                        <div className="headlineBox__body__commentHeader">
                          <img
                            className="headlineBox__body__commentHeader__avatar"
                            src={avatar}
                            alt="user avatar"
                          />
                          <p className="headlineBox__body__commentHeader__username">
                            username1
                          </p>
                          <p className="headlineBox__body__commentHeader__interval">
                            1 day ago
                          </p>
                        </div>
                        <MdMoreHoriz className="headlineBox__body__commentMoreDots" />
                      </div>
                      <p className="headlineBox__body__commentBodyText">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. In eu mi bibendum neque egestas congue
                        quisque egestas. Pellentesque eu tincidunt tortor
                        aliquam nulla facilisi cras fermentum.
                      </p>
                      <div className="headlineBox__body__commentDetails">
                        <div className="headlineBox__body__commentDetails__wrapper">
                          <img
                            className="headlineBox__body__commentDetails__image"
                            src={loveIcon}
                            alt="like icon"
                          />
                          <p className="headlineBox__body__commentDetails__text">
                            25
                          </p>
                        </div>
                        <div className="headlineBox__body__commentDetails__wrapper">
                          <img
                            className="headlineBox__body__commentDetails__image"
                            src={comment}
                            alt="like icon"
                          />
                          <p className="headlineBox__body__commentDetails__text">
                            Reply
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <p className="noPostsText">
          There are no posts to show in this category/topic
        </p>
      )}
      {/* </div> */}
    </>
  );
};

export default HeadlineCards;
