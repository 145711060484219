import React from 'react';
import s from './WatchingSection.module.scss';

const WatchingSection = () => {
  return (
    <div className={s.box}>
      <p className={s.box__heading}>Watching</p>
      <div className={s.box__body}>
        <div className={s.box__container}>
          <p className={s.box__container__title}>Categories</p>
          <p className={s.box__container__add}>+ add new category</p>
        </div>
        <div className={s.box__categories}>
          <p className={s.box__categories__text}>Science</p>
          <p className={s.box__categories__text}>Environment</p>
        </div>
        <p className={s.box__showMore}>show more...</p>
      </div>

      <div className={s.box__body}>
        <div className={s.box__container}>
          <p className={s.box__container__title}>Topics</p>
          <p className={s.box__container__add}>+ add new topic</p>
        </div>
        <div className={s.box__categories}>
          <p className={s.box__categories__text}>Biology</p>
          <p className={s.box__categories__text}>Neuroscience</p>
          <p className={s.box__categories__text}>Artificial Intelligence</p>
          <p className={s.box__categories__text}>Cosmology</p>
        </div>
        <p className={s.box__showMore}>show more...</p>
      </div>

      <div className={s.box__body}>
        <div className={s.box__container}>
          <p className={s.box__container__title}>Publishers</p>
          <p className={s.box__container__add}>+ add new publisher</p>
        </div>
        <div className={s.box__categories}>
          <p className={s.box__categories__text}>Nasa.gov</p>
          <p className={s.box__categories__text}>Mytimes.com</p>
          <p className={s.box__categories__text}>Nature.com</p>
        </div>
        <p className={s.box__showMore}>show more...</p>
      </div>

      <div className={`${s.box__body} ${s.box__bodyLast}`}>
        <div className={s.box__container}>
          <p className={s.box__container__title}>Channels</p>
          <p className={s.box__container__add}>+ add new channel</p>
        </div>
        <div className={s.box__categories}>
          <p className={s.box__categories__text}>Nasa-gov-news</p>
          <p className={s.box__categories__text}>Nasa-gov-goddard</p>
          <p className={s.box__categories__text}>Bbc</p>
        </div>
        <p className={s.box__showMore}>show more...</p>
      </div>
    </div>
  );
};

export default WatchingSection;
