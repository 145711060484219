import React, { Fragment } from 'react';
import { Text } from '@deposits/ui-kit-react';
import { Typography } from '@material-ui/core';
import helpIcon from '../Icons/helpIcon.svg';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box } from '@deposits/ui-kit-react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import SocialShare from '../components/social-share/SocialShare';
import commentIcon from '../Icons/commentIcon.svg';
import thumbsUpIcon from '../Icons/thumbsUpIcon.svg';
import thumbsDownIcon from '../Icons/thumbsDownIcon.svg';
import bookmarkIcon from '../Icons/bookmarkIcon.svg';
import shadows from '@material-ui/core/styles/shadows';
import { Grid } from 'swiper';

// Function to capitalize the first letter of a string
export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// Function to check if a string is empty
export function isEmptyString(str) {
  return str.trim() === '';
}

// Function to reverse a string
export function reverseString(str) {
  return str.split('').reverse().join('');
}

// Function to count the number of occurrences of a substring in a string
export function countSubstringOccurrences(str, substring) {
  const regex = new RegExp(substring, 'g');
  const matches = str.match(regex);
  return matches ? matches.length : 0;
}

export function ReplaceSpecialCharacters(str) {
  var buffer = str.replace('&amp;', '&');
  buffer = buffer.replace('&quot;', '"');
  buffer = buffer.replace('&apos;', "'");
  buffer = buffer.replace('&lt;', '<');
  buffer = buffer.replace('&gt;', '>');
  buffer = buffer.replace('&nbsp;', ' ');
  buffer = buffer.replace('&cent;', '¢');
  buffer = buffer.replace('&pound;', '£');
  buffer = buffer.replace('&yen;', '¥');
  buffer = buffer.replace('&euro;', '€');
  buffer = buffer.replace('&copy;', '©');
  buffer = buffer.replace('&reg;', '®');
  buffer = buffer.replace('&trade;', '™');
  buffer = buffer.replace('&times;', '×');
  buffer = buffer.replace('&divide;', '÷');
  buffer = buffer.replace('&ndash;', '–');
  buffer = buffer.replace('&mdash;', '—');
  buffer = buffer.replace('&lsquo;', '‘');
  buffer = buffer.replace('&rsquo;', '’');
  buffer = buffer.replace('&sbquo;', '‚');
  buffer = buffer.replace('&ldquo;', '');
  buffer = buffer.replace('&rdquo;', '”');
  buffer = buffer.replace('&bdquo;', '„');
  buffer = buffer.replace('&laquo;', '«');
  buffer = buffer.replace('&raquo;', '»');
  buffer = buffer.replace('&lsaquo;', '‹');
  buffer = buffer.replace('&rsaquo;', '›');
  buffer = buffer.replace('&nbsp;', ' ');
  buffer = buffer.replace('&iexcl;', '¡');
  buffer = buffer.replace('&#8220;', '“');
  buffer = buffer.replace('&#8221;', '”');
  buffer = buffer.replace('&#8211;', '-');
  buffer = buffer.replace('&#8216;', '`');

  return buffer;
}

// write function that takes in a string and returns n number of characters of that string
// if the string is longer than n characters, add '...' to the end of the string
// if the string is shorter than n characters, return the string as is
// example:
export function truncateString(str, n) {
  let buffer = str.trim();

  buffer = ReplaceSpecialCharacters(buffer);

  if (buffer.length > n) {
    return buffer.slice(0, n) + '...';
  } else {
    return buffer;
  }
}

// function that takes in a number and returns a string with n decimal places
// example:
// 1.23456789 => '1.23'
// 1.2 => '1.20'
// 1 => '1.00'
function truncateNumber(num, n) {
  return num.toFixed(n);
}

// add a function that takes in a date string and returns a string with the date formatted as a Days Ago string
/*
function daysAgo(dateString) {
  const date = new Date(dateString);
  const today = new Date();
  const diff = today.getTime() - date.getTime();
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  return `${days} days ago`;
}
*/

// define a function that takes in a veracity score value and returns a string with the veracity score formatted as a string assessing the truth value of the text passage using the following rules:
// Veracity Score, a number value from 0.0 through 1.0 where 1.0 denotes highest truth value and 0.0 denotes lowest truth value, based on the veracity of the facts extracted from the text passage
// Sentiment Score, a number value from from -1.0 through 1.0 where 1.0 denotes maximum positivity and 0.0 denotes neutral sentiment and -1.0 denotes maximum negativity, based on the sentiment of the text passage

export function getVeracityString(veracityScore) {
  //let value = veracityScore;
  let value = Math.ceil(veracityScore * 100) / 100;
  let buffer = '';

  // if the score is not within the 0 to 1.0 range, return 'N/A'
  if (veracityScore <= 0 || veracityScore > 1) {
    return 'N/A';
  }

  if (value >= 0.9) {
    buffer = 'Very High';
  } else if (value >= 0.7) {
    buffer = 'High';
  } else if (value >= 0.5) {
    buffer = 'Moderate';
  } else if (value >= 0.3) {
    buffer = 'Low';
  } else if (value >= 0.1) {
    buffer = 'Very Low';
  } else if (value > 0.0) {
    buffer = 'Unreliable';
  } else {
    buffer = 'N/A';
  }

  // log parameter and return value in one line
  //console.log(`getVeracityString - veracityScore: ${veracityScore} - buffer: ${buffer}`);

  return buffer;
}

// define a function that takes in a sentiment score value and returns a string with the sentiment score formatted as a string assessing the sentiment of the text passage using the following rules:
// Veracity Score, a number value from 0.0 through 1.0 where 1.0 denotes highest truth value and 0.0 denotes lowest truth value, based on the veracity of the facts extracted from the text passage
// Sentiment Score, a number value from from -1.0 through 1.0 where 1.0 denotes maximum positivity and 0.0 denotes neutral sentiment and -1.0 denotes maximum negativity, based on the sentiment of the text passage

export function getSentimentString(sentimentScore) {
  // round the sentiment score to 2 decimal places
  let value = Math.ceil(sentimentScore * 100) / 100;
  let buffer = '';

  // if the score is not within the -1.0 to 1.0 range, return 'Not calculated'
  if (sentimentScore < -1 || sentimentScore > 1) {
    return 'N/A';
  }

  if (value >= 0.9) {
    buffer = 'Very Positive';
  } else if (value >= 0.7) {
    buffer = 'Positive';
  } else if (value >= 0.5) {
    buffer = 'Moderately Positive';
  } else if (value >= 0.3) {
    buffer = 'Slightly Positive';
  } else if (value >= 0.1) {
    buffer = 'Neutral';
  } else if (value >= -0.1) {
    buffer = 'Neutral';
  } else if (value >= -0.3) {
    buffer = 'Slightly Negative';
  } else if (value >= -0.5) {
    buffer = 'Moderately Negative';
  } else if (value >= -0.7) {
    buffer = 'Negative';
  } else if (value >= -0.9) {
    buffer = 'Very Negative';
  }

  // log parameter and return value in one line
  //console.log(`getSentimentString - sentimentScore: ${sentimentScore} - buffer: ${buffer}`);

  return buffer;
}

// 10. Political Lean, a number value from from -1.0 through 1.0 where 1.0 denotes maximum conservative views or far-right views, and 0.0 denotes neutralality or centric political viewpoint and -1.0 denotes maximum liberalism or left-leaning views, based on the political lean of the text passage
// define a function that takes in a political lean score value and returns a string with the political lean score formatted as a string assessing the political lean of the text passage using the following rules:

export function getPoliticalLeanString(politicalLeanScore) {
  //let value = politicalLeanScore;
  let value = Math.ceil(politicalLeanScore * 100) / 100;

  let buffer = '';

  // if the score is not within the -1.0 to 1.0 range, return 'Not calculated'
  if (politicalLeanScore < -1 || politicalLeanScore > 1) {
    return 'N/A';
  }

  if (value >= 0.9) {
    buffer = 'Very Conservative';
  } else if (value >= 0.7) {
    buffer = 'Conservative';
  } else if (value >= 0.5) {
    buffer = 'Moderately Conservative';
  } else if (value >= 0.3) {
    buffer = 'Slightly Conservative';
  } else if (value >= 0.1) {
    buffer = 'Neutral';
  } else if (value >= -0.1) {
    buffer = 'Neutral';
  } else if (value >= -0.3) {
    buffer = 'Slightly Liberal';
  } else if (value >= -0.5) {
    buffer = 'Moderately Liberal';
  } else if (value >= -0.7) {
    buffer = 'Liberal';
  } else if (value >= -0.9) {
    buffer = 'Very Liberal';
  }

  // log parameter and return value in one line
  //console.log(`getPoliticalLeanString - politicalLeanScore: ${politicalLeanScore} - buffer: ${buffer}`);

  return buffer;
}

export function getScoresFormatted(
  veracityScore,
  sentimentScore,
  politicalLeanScore,
) {
  let buffer = `Veracity Score: ${veracityScore.toFixed(
    2,
  )} - ${getVeracityString(
    veracityScore,
  )}\nSentiment Score: ${sentimentScore.toFixed(2)} - ${getSentimentString(
    sentimentScore,
  )}\nPolitical Lean: ${politicalLeanScore.toFixed(
    2,
  )} - ${getPoliticalLeanString(politicalLeanScore)}`;
  //console.log('getScoresFormatted - buffer:\n', buffer);
  return buffer;
}

// define a function that takes in a post and represents category and topics as a formatted string
// example:
// {
//   "id": "1",
//   "title": "The title of the post",
//   "description": "The description of the post",
//   "category": "science",
//   "topics": [
//     "biology",
//     "chemistry"
//   ]
// }
// should return:
// "Science - Biology, Chemistry"
// if the post does not have a category or topics, return an empty string
// if the post has a category but no topics, return the category
// if the post has topics but no category, return the topics as a comma separated string
// if the post has both a category and topics, return the category followed by the topics as a comma separated string
export function getCategoryAndTopicsString(post) {
  // log post parameter by dumping the object to the console - with friendly formatting
  //console.log('getCategoryAndTopicsString - post:\n', JSON.stringify(post, null, 2));

  let buffer = '';
  if (post.category && post.metadata && post.metadata.tags) {
    buffer = `CATEGORY: ${post.category.toUpperCase()}\nTOPICS: ${joinStrings(
      post.topics,
      ', ',
    )}`;
  } else if (post.category) {
    buffer = `${post.category.toUpperCase()}`;
  } else if (post.topics) {
    buffer = `${joinStrings(post.topics, ', ')}`;
  } else {
    buffer = '';
  }

  // log parameter and return value in one line
  //console.log(`getCategoryAndTopicsString - buffer:\n${buffer}`);
  return buffer;
}

export function generateCategoryButton(category) {
  if (!category) return null;

  var category = category.toUpperCase();
  var categoryWithPrefix = 'CATEGORY: ' + category;

  return (
    <Button
      title={categoryWithPrefix}
      variant="contained"
      style={{
        borderRadius: '25px',
        fontSize: '.8rem',
        left: '1%',
        top: '5%',
        right: '1%',
        backgroundColor: 'var(--color-header)',
        color: '#FFFFFF',
        fontWeight: '700',
      }}
      onClick={() => {
        window.location.href = `/${category.toLowerCase()}`;
      }}
    >
      {category}
    </Button>
  );
}

export function generateTopicButton(topic, index) {
  if (!topic) return null;
  var topicLower = 'TAG: ' + topic.toLowerCase();

  return (
    <Button
      key={index}
      title={topicLower}
      style={{
        //left position should index * width of button + 2px
        position: 'relative',
        cursor: 'text',
        left: index * 5 + 15,
        top: '88%',
        width: '120px',
        backgroundColor: 'var(--color-underline)',
        fontSize: '12px',
        borderRadius: '15px',
        border: 'var(--color-border)',
        display: 'inline-flexbox',
        color: '#FFFFFF',
        alignContent: 'center',
        textAlign: 'center',
        fontweight: '500',
      }}
    >
      {topic}
    </Button>
  );
}
export function generateTagButton(tag, index, maxLength) {
  if (!tag) return null;
  var tagClean = truncateString(tag, maxLength);

  return (
    <Text
      key={index}
      title={'TAG: ' + tag}
      style={{
        //left position should index * width of button + 2px
        position: 'relative',
        cursor: 'text',

        width: '200px',
        backgroundColor: '#AAAAAA',
        fontSize: '1.0rem',
        borderRadius: '5px',
        border: 'var(--color-border)',
        display: 'inline-flexbox',
        color: '#FFFFFF',
        alignContent: 'center',
        textAlign: 'center',
        fontweight: '400',
        padding: '2px',
      }}
    >
      {tagClean}
    </Text>
  );
}

export function generateTopic(topic, index) {
  if (!topic) return null;

  var topic = topic.toLowerCase();
  var topicWithTag = 'TOPIC: ' + topic;
  return (
    <Text
      key={index}
      title={topicWithTag}
      style={{
        fontSize: '1.0rem',
        width: '200px',
        backgroundColor: 'var(--color-underline)',
        borderRadius: '5px',
        border: 'var(--color-border)',
        display: 'inline-flexbox',
        color: '#FFFFFF',
        alignContent: 'center',
        textAlign: 'center',
        padding: '2px',
      }}
      //onClick={handleSearchClick(topic)}
    >
      {topic}
    </Text>
  );
}
export function getSummaryList(post) {
  if (!post) return null;
  if (!post.metadata) return null;

  var summaryList = [];
  if (post.metadata.generatedSummary1)
    summaryList.push({
      enabled: true,
      title: 'AI SUMMARY',
      note: 'the following summary and scores were generated by an AI algorithm and may not be accurate',
      AIsummary: cleanSummary(post, false),
    });
  else
    summaryList.push({
      enabled: false,
      title: 'NO AI SUMMARY',
      note: 'the following summary and scores were generated by an AI algorithm and may not be accurate',
      AIsummary: '',
    });

  /*
    if (post.topics.length > 0)
    summaryList.push({
      enabled: true,
      title: "Topics & Tags",
      AIsummary:  post.topics.map((topic) => generateTopic(topic)),
    });
*/

  // if (post.metadata.tags.length > 0)
  //   summaryList.push({
  //     enabled: true,
  //     title: 'Topics & Tags',
  //     AIsummary: getTagsArray(post),
  //   });
  // else if (post.metadata.tags.length === 0 && post.topics.length === 0)
  //   summaryList.push({
  //     enabled: false,
  //     title: 'Topics & Tags',
  //     AIsummary: 'No topics or Tags available',
  //   });

  return summaryList;
}

export function filterFuturePosts(posts, category) {
  if (!posts) return null;

  const now = new Date();
  const tomorrow = new Date(now);
  tomorrow.setDate(tomorrow.getDate() + 2);

  var currentPosts = posts.filter(
    (post) => new Date(post.publisher.publishDate) <= tomorrow,
  );

  // filter posts by category
  if (category !== 'all' || category === undefined || category === null) {
    var filteredPosts = currentPosts.filter(
      (post) => post.category === category,
    );
    return filteredPosts;
  } else {
    return posts;
  }
}

export function randomizeByCategory(posts, maxPosts) {
  if (!posts) return null;

  console.log(
    'randomizeByCategory - # posts:',
    posts.length,
    'maxPosts:',
    maxPosts,
  );

  // group future posts by category
  var categories = [];
  var categoryPosts = [];
  var i = 0;

  // group together posts by category but ensure duplicates are or not allowed
  while (i < posts.length) {
    var category = posts[i].category;
    if (categories.includes(category)) {
      categoryPosts[category].push(posts[i]);
    } else {
      categories.push(category);
      categoryPosts[category] = [];
      categoryPosts[category].push(posts[i]);
    }
    i++;
  }
  //console.log("categories:", categories);
  //console.log("categoryPosts:", categoryPosts);

  // get 1 random posts from each category
  var heroPosts = [];
  var i = 0;
  while (i < categories.length) {
    var randomIndex = Math.floor(
      Math.random() * categoryPosts[categories[i]].length,
    );
    heroPosts.push(categoryPosts[categories[i]][randomIndex]);
    i++;
  }

  // get 3 random posts from heroPosts
  var randomPosts = [];
  var i = 0;

  if (maxPosts === undefined || maxPosts === null) maxPosts = 3;
  if (heroPosts.length < maxPosts) maxPosts = heroPosts.length;

  while (i < maxPosts) {
    var randomIndex = Math.floor(Math.random() * heroPosts.length);
    randomPosts.push(heroPosts[randomIndex]);
    heroPosts.splice(randomIndex, 1);
    i++;
  }

  //console.log("randomizeByCategory:", randomPosts);

  return randomPosts;
}

export function getFaqData() {
  const faqData = [
    {
      question: 'What is SMARTAI.NEWS?',
      answer:
        'SMARTAI.NEWS is a modern news and blog aggregation platform concept, primarily composed of public access, non-profit and government feeds and various blogs. ',
    },
    {
      question:
        'What generative AI capabilities are integrated into the SMARTAI.NEWS solution?',
      answer:
        'SMARTAI.NEWS integrates an array of generative AI capabilities from the SmartAI Generative Platform (SSGP), including AI-driven summaries, enriched media content, and future beta features including innovative scoring systems for sentiment, political lean, and truth veracity. ' +
        'These features enhance the transparency and trustworthiness of the news content​​',
    },
    {
      question: 'Where at the images from?',
      answer:
        'SMARTAI.NEWS generates relevant, contextual images for each article using AI. The images are generated using the latest state-of-the-art AI models and prompt curation engines from the SmartAI Generative Platform (SSGP). ',
    },
    {
      question:
        'How do I recommend a news / blog / podcast or channel to be included in the SMARTAI.NEWS platform?',
      answer:
        'SMARTAI.NEWS is open to recommendations for new feeds to be included in the platform. Please send your suggestions to either our email or SmartAI Inquiry (links below - located in the Still have questions? section). \n\nWe will ' +
        'review any public access, creative commons or open source news feed (RSS, Atom, JSON, API, etc.) and add it to the platform if it meets our criteria for inclusion.',
    },
    {
      question:
        'How do I inquire about adding our non-profit, commerical, goverment or private news feed, blog, podcast or journal to the SMARTAI.NEWS platform?',
      answer:
        'SMARTAI.NEWS is eager to partner with government, non-profit, private, and/or commercial media organizations, and would love to add your feeds to our platform,  ' +
        'Please contact us at any of the links below, in the Still have questions? section.',
    },
    {
      question:
        'How does SMARTAI.NEWS ensure the quality of its news articles?',
      answer:
        "SMARTAI.NEWS employs both human curation of news channels and AI's precision to select only insightful articles. It uses an AI enhanced categorization and tagging system and future release " +
        'will incorporate an innovative scoring mechanism to assess sentiment, political lean, and truthfulness, ensuring the trustworthiness and quality of the news provided',
    },
    {
      question: 'Can we trust the AI summary provided for each article?',
      answer:
        'Yes (most of the time), SMARTAI.NEWS uses the latest state-of-the-art AI models to generate summaries for each article, ensuring that the content is accurate and trustworthy, ' +
        'but currently, even the best AI models can make mistakes. ',
    },
    {
      question: 'Why do some articles not have a summary available?',
      answer:
        'Some articles may not have a summary available, due to the AI summarization engines not being able to either access the article for processing, or due to technical issues.',
    },
    {
      question: 'Are there translations available for the news articles?',
      answer:
        'Yes (in BETA release), SMARTAI.NEWS will offer translations across a broad selection of languages, making it accessible to a diverse audience.',
    },
    {
      question: 'Can SMARTAI.NEWS help me understand complex articles?',
      answer:
        'Absolutely. The platform provides summaries across different reading levels, ensuring that everyone can understand the content regardless of their reading proficiency.',
    },
    {
      question:
        'In what ways does the SmartAI Generative Platform enhance the user experience in SMARTAI.NEWS?',
      answer:
        'The SmartAI Generative Platform enhances user experience by providing a hyper-integrated environment that supports advanced AI workflows, ' +
        'including user identity and authentication, subscription management, a credits subsystem and AI prompt curation. This results in a seamless and ' +
        'intuitive interaction with the SMARTAI.NEWS solution.​  Find out more at https://smartai.systems/platform/',
    },
  ];

  return faqData;
}

export function getTagsArray(post) {
  if (!post) return null;
  if (!post.metadata) return null;

  // clean up tags
  var tagsDirty = post.metadata.tags;
  var tagsClean = tagsDirty.map((tag) => tag.trim());
  var tags = tagsClean.filter((tag) => tag !== '');

  // clean and truncate tags
  var tagsCleaned = tags.map((tag) => CleanString(tag));

  // split up tags that are delimited by '-' or ','
  var tagsSplit = [];
  tagsCleaned.forEach((tag) => {
    if (tag.includes('-')) {
      var tagSplit = tag.split('-');
      tagsSplit = tagsSplit.concat(tagSplit);
    } else if (tag.includes(',')) {
      var tagSplit = tag.split(',');
      tagsSplit = tagsSplit.concat(tagSplit);
    } else if (tag.includes('. ')) {
      var tagSplit = tag.split('. ');
      tagsSplit = tagsSplit.concat(tagSplit);
    } else {
      tagsSplit.push(tag);
    }
  });

  // clean and truncate tags
  tagsSplit = [...new Set(tagsSplit)];
  var tagsLower = tagsSplit.map((tag) => tag.toLowerCase());
  var tagsReduced = tagsLower.slice(0, 10);

  // remove any tags that already exist in post.topics
  tagsReduced = tagsReduced.filter((tag) => !post.topics.includes(tag));
  var tagsStyled = tagsReduced.map((tag, index) =>
    generateTagButton(tag, index, 20),
  );
  tagsStyled = tagsStyled.sort();

  var topics = post.topics.map((topic) => generateTopic(topic));
  topics = topics.sort();

  // now add topics and tags together
  topics = topics.concat(tagsStyled);
  topics = topics.sort();
  // remove duplicates, ignore case
  topics = [...new Set(topics.map((topic) => topic))];

  return (
    // setup Box as a grid layout
    <Box
      style={{
        display: 'grid',
        backgroundColor: 'var(--color-text)',
        borderRadius: '0px',
        padding: '10px',
        width: '100%',
        alignContent: 'center',
        justifyContent: 'center',
      }}
    >
      <Box>
        {
          //topics}
        }
      </Box>
      <Box> {topics}</Box>
    </Box>
  );
}

export function AIGeneratedSection(post) {
  if (!post) return null;

  var summary = cleanSummary(post, false);
  if (summary.length > 100) {
    summary = 'AI SUMMARY:  ' + summary.substring(0, 100) + '...';
  }

  if (summary.length < 10) {
    summary = 'No AI summary available';
  }

  var summaryList = getSummaryList(post);

  return (
    // make sure all contents are contained within
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'var(--color-background)',
        boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.5)',
        borderRadius: '0px',
        width: '100%',
        alignContent: 'center',
      }}
    >
      {summaryList.map((s, index) => (
        <Accordion
          key={index}
          style={{
            width: '100%',
            backgroundColor: 'var(--color-text)',
            boxShadow: '0px 0px 1px 0px var(--color-border)',
            borderRadius: '0px',
          }}
        >
          <AccordionSummary expandIcon={s.enabled ? <ExpandMoreIcon /> : null}>
            <Box
              style={{
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                color: '#ffffff',
                // background color should show --color-border if enabled, otherwise, show --color-text
                backgroundColor: s.enabled
                  ? 'var(--color-border)'
                  : 'var(--color-text)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '1rem',
              }}
              is="img"
              src={helpIcon}
              title={
                index === 0
                  ? `${cleanSummary(post)}`
                  : getCategoryAndTopicsString(post)
              }
            />
            <Typography
              style={{
                fontSize: '1rem',
                fontWeight: '700',
                fontStyle: 'italic',

                color: s.enabled ? 'var(--color-border)' : '#aaaaaa',
              }}
            >
              {s.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{
              color: 'var(--color-text)',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography>{s.AIsummary}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

export function NewAIGeneratedSection(post) {
  if (!post) return null;

  var summary = cleanSummary(post, false);
  if (summary.length > 100) {
    summary = 'AI SUMMARY:  ' + summary.substring(0, 100) + '...';
  }

  if (summary.length < 10) {
    summary = 'No AI summary available';
  }

  var summaryList = getSummaryList(post);

  return (
    // make sure all contents are contained within
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'var(--color-background)',
        boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.5)',
        borderRadius: '0px',
        width: '100%',
        alignContent: 'center',
      }}
    >
      {summaryList.map((s, index) => (
        <Accordion
          key={index}
          style={{
            width: '100%',
            backgroundColor: 'var(--color-text)',
            boxShadow: '0px 0px 1px 0px var(--color-border)',
            borderRadius: '0px',
          }}
        >
          <AccordionSummary expandIcon={s.enabled ? <ExpandMoreIcon /> : null}>
            <Box
              style={{
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                color: '#ffffff',
                // background color should show --color-border if enabled, otherwise, show --color-text
                backgroundColor: s.enabled
                  ? 'var(--color-border)'
                  : 'var(--color-text)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '1rem',
              }}
              is="img"
              src={helpIcon}
              title={
                index === 0
                  ? `${cleanSummary(post)}`
                  : getCategoryAndTopicsString(post)
              }
            />
            <Typography
              style={{
                fontSize: '1rem',
                fontWeight: '700',
                fontStyle: 'italic',

                color: s.enabled ? 'var(--color-border)' : '#aaaaaa',
              }}
            >
              {s.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{
              color: 'var(--color-text)',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography>{s.AIsummary}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

export function channelSection(post) {
  return (
    <Box>
      <Text
        style={{
          fontSize: '0.9rem',
          fontStyle: 'italic',
          color: 'var(--color-text)',
        }}
      >
        {post.publisher.publisherName} | {post.channel.channelId}
        {
          //getChannelString(post.channel.channelId)
        }
      </Text>
      <Text
        style={{
          fontSize: '0.8rem',
          fontStyle: 'italic',
          color: 'var(--color-text)',
        }}
      >
        {daysAgo(post.publisher.publishDateString)}
      </Text>
    </Box>
  );
}

export function showPostBody(post) {
  if (!post) return null;
  // detect if mobile and return different layout
  const isMobile = window.innerWidth <= 500;
  //console.log("showPostBody - mobile", window.innerWidth, isMobile);

  var bodyHeight = '150px';

  return (
    <Box height={bodyHeight}>
      <Link to={post.url} title={post.title}>
        <Text className="header__blog__text">
          {truncateString(post.title, isMobile === true ? 50 : 100)}
        </Text>
      </Link>
      {channelSection(post)}
    </Box>
  );
}

export function cleanSummary(post, includeDisclaimer) {
  var summary = '';
  if (post === null || post === undefined) return summary;
  if (post.metadata === null || post.metadata === undefined) return summary;
  if (
    post.metadata.generatedSummary1 === null ||
    post.metadata.generatedSummary1 === undefined
  )
    return summary;

  // clean up first summary and remove any leading or trailing whitespace
  summary = post.metadata.generatedSummary1.trim();

  // remove the following substrings from the summary ("SummaryGradStudent")
  summary = summary.replace('SummaryGradStudent', '');

  // remove the following characters from the summary (".",",","[","]","(",")","-","_","~")
  summary = summary.replace('[', '');
  summary = summary.replace(']', '');
  summary = summary.replace('(', '');
  summary = summary.replace(')', '');
  summary = summary.replace('-', '');
  summary = summary.replace('_', '');
  summary = summary.replace(':', '');
  summary = summary.replace('1.', '');
  summary = summary.replace('2.', '');
  summary = summary.replace('3.', '');
  summary = summary.replace('4.', '');
  summary = summary.replace('5.', '');

  summary = ReplaceSpecialCharacters(summary);

  summary = summary.trim();

  if (summary.length > 0) {
    // check if include discalimer
    if (includeDisclaimer === true || includeDisclaimer === undefined) {
      var disclaimer =
        'DISCLAIMER: The following summary was generated by an AI algorithm and may not be accurate. \n\nSUMMARY: ';
      summary = disclaimer + summary;
    }
  } else {
    summary = 'No AI summary available.';
  }

  return summary;
}
export function renderMetadata(post, includeSummary) {
  if (!post.metadata) return null;
  //if (toggle === 0) return null;

  let summaryText = '';

  // create buffer from all summaries together
  if (includeSummary === true) {
    summaryText += cleanSummary(post);
  }

  // if no summaries, use description
  //if (summaryText === "") summaryText = "DESCRIPTION:\n" + truncateString(post.description, 200);

  // if no description, use title
  //if (summaryText === "") summaryText = "TITLE: " + truncateString(post.title, 100);

  var topicsBuffer = getCategoryAndTopicsString(post);
  if (topicsBuffer !== '') summaryText = summaryText + topicsBuffer;

  // if no title, use empty string
  if (summaryText === '') summaryText = 'No tags';

  return summaryText;
}
// define a function that takes an array of strings and joins each member of the string after removing any leading or trailing whitespace and removes the following character from each string '[',']','(',')' and returns a joined string
// example:
// ['  a', 'b  ', '  c  '] => 'a, b, c'
// ['a', 'b', 'c'] => 'a, b, c'
// ['a'] => 'a'
// [] => ''
export function joinStrings(strs, delimiter) {
  // for each string in string array, remove parenthesis or brackets or numbers from each string then return joined string
  //return strs.map(str => str.replace(/[\[\]\(\)\d]/g, '').trim()).join(', ');

  // loop through string array and perform the following on each string
  // if str is empty, remove it from the array
  // remove parenthesis or brackets or numbers from each string
  // remove leading and trailing whitespace from each string
  // if str is empty, remove it from the array
  // a string have no more than 2 words in it

  for (let str of strs) {
    // if str is empty, remove it from the array
    if (str === '') {
      strs.splice(strs.indexOf(str), 1);
    }
  }
  for (let str of strs) {
    // remove parenthesis or brackets or numbers from each string
    // remove leading and trailing whitespace from each string
    //str = str.replace(/[\[\]\(\)\d]/g, '').trim();

    str = CleanString(str);
  }

  let str = strs.join(delimiter);

  return str;
}

export function CleanString(str) {
  // remove periods and commas
  str = str.replace('.', '').trim();
  str = str.replace(',', '').trim();
  str = str.replace('[', '').trim();
  str = str.replace(']', '').trim();
  str = str.replace('(', '').trim();
  str = str.replace(')', '').trim();

  // remove numerals from string
  str = str.replace('1', '').trim();
  str = str.replace('2', '').trim();
  str = str.replace('3', '').trim();
  str = str.replace('4', '').trim();
  str = str.replace('5', '').trim();
  str = str.replace('6', '').trim();
  str = str.replace('7', '').trim();
  str = str.replace('8', '').trim();
  str = str.replace('9', '').trim();
  str = str.replace('10', '').trim();
  str = str.replace('11', '').trim();
  str = str.replace('12', '').trim();
  str = str.replace('1.', '').trim();
  str = str.replace('2.', '').trim();
  str = str.replace('3.', '').trim();
  str = str.replace('4.', '').trim();
  str = str.replace('5.', '').trim();
  str = str.replace('6.', '').trim();
  str = str.replace('7.', '').trim();
  str = str.replace('8.', '').trim();
  str = str.replace('9.', '').trim();
  str = str.replace('10.', '').trim();
  str = str.replace('11.', '').trim();
  str = str.replace('12.', '').trim();
  str = str.replace('-', '').trim();
  str = str.replace('_', '').trim();
  str = str.replace('~', '').trim();
  str = str.replace(/\d/g, '').trim();

  // remove break tag
  str = str.replace('<br>', '').trim();
  str = str.replace('<br />', '').trim();

  // remove leading and trailing whitespace from each string
  str = str.trim();

  return str;
}

// define a function that takes in a date string and performs the following:
// 1. creates a Date object from the date string
// 2. creates a Date object for today's date
// 3. calculates the difference between the two dates in milliseconds
// 4. calculates the difference between the two dates in days
// 5. calculates the difference between the two dates in hours
// 6. calculates the difference between the two dates in minutes
// 7. calculates the difference between the two dates in seconds
// 8. returns a string with the date formatted as a Days Ago string if the difference in days is greater than 0
// 9. returns a string with the date formatted as a Hours Ago string if the difference in hours is greater than 0
// 10. returns a string with the date formatted as a Minutes Ago string if the difference in minutes is greater than 0
// 11. returns a string with the date formatted as a Seconds Ago string if the difference in seconds is greater than 0
// 12. returns a string with the date formatted as a Just Now string if the difference in seconds is 0

export function daysAgo(dateString) {
  const date = new Date(dateString);
  const today = new Date();
  const diff = today.getTime() - date.getTime();
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor(diff / (1000 * 60));
  const seconds = Math.floor(diff / 1000);

  if (days > 0) {
    if (days === 1) {
      return `${days} day ago`;
    } else {
      return `${days} days ago`;
    }
  } else if (hours > 0) {
    return `${hours} hours ago`;
  } else if (minutes > 0) {
    return `${minutes} minutes ago`;
  } else if (seconds > 0) {
    return `${seconds} seconds ago`;
  } else {
    return `Just now`;
  }
}

export function getChannelString(channelId) {
  switch (channelId) {
    case 'pensoft-rethinking-ecology':
      return 'Pensoft - Rethinking Ecology';
    case 'pensoft-travaux':
      return 'Travaux du Muséum National d’Histoire Naturelle';
    case 'pensoft-folia-medica':
      return 'Pensoft - Folia Medica';
    case 'pensoft-italian-botanist':
      return 'Pensoft - Italian Botanist';
    case 'pensoft-biorisk':
      return 'Pensoft - Biorisk';
    case 'pensoft-evolsyst':
      return 'Pensoft - Evolsyst';
    case 'pensoft-one-ecosystem':
      return 'Pensoft - One Ecosystem';
    case 'pensoft-evolutionary-systematics':
      return 'Pensoft - Evolutionary Systematics';
    case 'pensoft-research-ideas-and-outcomes':
      return 'Pensoft - Research Ideas and Outcomes';
    case 'pensoft-zookeys':
      return 'Pensoft - ZooKeys';
    case 'pensoft-phytokeys':
      return 'Pensoft - PhytoKeys';
    case 'pensoft-mycokeys':
      return 'Pensoft - MycoKeys';

    case 'nasa-gov-news':
      return 'NASA - News';
    case 'nasa-gov-technology':
      return 'NASA - Technology';
    case 'nasa-gov-aeronautics':
      return 'NASA - Aeronautics';
    case 'nasa-gov-space-station':
      return 'NASA - Space Station';
    case 'nasa-gov-space-artemis':
      return 'NASA - Artemis Program';
    case 'nasa-gov-podcast-houston':
      return 'NASA - Houston We Have a Podcast';
    case 'nasa-gov-podcast-curious-universe':
      return 'NASA - Curious Universe Podcast';
    case 'nasa-gov-podcast-steps-leaps':
      return 'NASA - Small Steps, Giant Leaps';
    case 'nasa-gov-ames-research':
      return 'NASA - Ames Research Center';
    case 'nasa-gov-goddard':
      return 'NASA - Goddard Space Flight Center';
    case 'nasa-gov-jpl':
      return 'NASA - Jet Propulsion Laboratory';
    case 'nasa-gov-johnson':
      return 'NASA - Johnson Space Center';
    case 'nasa-gov-kennedy':
      return 'NASA - Kennedy Space Center';
    case 'nasa-gov-langley':
      return 'NASA - Langley Research Center';
    case 'nasa-gov-marshall':
      return 'NASA - Marshall Space Flight Center';
    case 'nasa-gov-stennis':
      return 'NASA - Stennis Space Center';
    case 'us-nih-nlm-director':
      return 'NIH - NLM Musings from the Mezzanine';
    case 'us-nih-nlm-general':
      return 'NIH - NLM General Announcements';
    case 'us-nih-medline-new':
      return 'NIH - MedlinePlus New Links';
    case 'us-nist-research-communications':
      return 'NIST - Advanced Communications';
    case 'us-nist-research-bioscience':
      return 'NIST - Bioscience';
    case 'us-nist-research-chemistry':
      return 'NIST - Chemistry';
    case 'us-nist-research-building':
      return 'NIST - Building';
    case 'us-nist-research-cybersecurity':
      return 'NIST - Cybersecurity';
    case 'us-nist-research-electronics':
      return 'NIST - Electronics';
    case 'us-nist-research-energy':
      return 'NIST - Energy';
    case 'us-nist-research-environment':
      return 'NIST - Environment';
    case 'us-nist-research-fire':
      return 'NIST - Fire';
    case 'us-nist-research-forensics':
      return 'NIST - Forensic Science';
    case 'us-nist-research-health':
      return 'NIST - Health';
    case 'us-nist-research-it':
      return 'NIST - Information Technology';
    case 'us-nist-research-manufacturing':
      return 'NIST - Manufacturing';
    case 'us-nist-research-materials':
      return 'NIST - Materials';
    case 'us-nist-research-math':
      return 'NIST - Mathematics & Statistics';
    case 'us-nist-research-nanotechnology':
      return 'NIST - Nanotechnology';
    case 'us-nist-research-performance':
      return 'NIST - Performance Excellence';
    case 'us-nist-research-physics':
      return 'NIST - Physics';
    case 'us-nist-research-public-safety':
      return 'NIST - Public Safety';
    case 'us-nist-research-standards':
      return 'NIST - Standards';
    case 'us-nist-research-transportation':
      return 'NIST - Transportation';
    case 'us-nist-research-blog-taking-measure':
      return 'NIST - Taking Measure Blog';
    case 'us-nist-research-blog-cybersecruity-insights':
      return 'NIST - Cybersecurity Insights Blog';
    case 'us-nsf-gov-research-news':
      return 'NSF - Research';
    case 'us-nsf-gov-discoveries':
      return 'NSF - Discoveries';
    case 'us-nsf-gov-multimedia':
      return 'NSF - Multimedia';
    case 'us-nsf-gov-special_reports':
      return 'NSF - Special Reports';
    case 'us-nsf-gov-publications':
      return 'NSF - Publications';
    case 'us-nsf-gov-news':
      return 'NSF - News';
    case 'us-nsf-gov-testimony':
      return 'NSF - Testimony';
    case 'us-nsf-gov-funding':
      return 'NSF - Funding';
    case 'us-nsf-gov-awards':
      return 'NSF - Awards';
    case 'us-nsf-gov-events':
      return 'NSF - Events';
    case 'us-nsf-gov-press':
      return 'NSF - Press';
    case 'us-nsf-gov-statistics':
      return 'NSF - Statistics';
    case 'us-nsf-gov-employment':
      return 'NSF - Employment';
    case 'us-nsf-gov-about':
      return 'NSF - About';
    case 'us-nsf-gov-website-policies':
      return 'NSF - Website Policies';
    case 'us-nsf-gov-privacy':
      return 'NSF - Privacy';
    case 'us-nsf-gov-plain-language':
      return 'NSF - Plain Language';
    case 'us-nsf-gov-information-quality':
      return 'NSF - Information Quality';
    case 'us-nsf-gov-no-fear-act':
      return 'NSF - No Fear Act';
    case 'us-nsf-gov-usa-gov':
      return 'NSF - USA.gov';
    case 'us-nsf-gov-foia':
      return 'NSF - FOIA';
    case 'us-nsf-gov-foia-reading-room':
      return 'NSF - FOIA Reading Room';
    case 'us-nsf-gov-foia-report':
      return 'NSF - FOIA Report';
    case 'us-nsf-gov-stem-statistics':
      return 'NSF - STEM Statistics';
    case 'us-state-gov-africa':
      return 'U.S. Dept. of State - Africa';
    case 'us-state-gov-climate-environment':
      return 'U.S. Dept. of State - Climate & Env.';
    case 'us-state-gov-east-asia-pacific':
      return 'U.S. Dept. of State - East Asia & Pacific';
    case 'us-state-gov-europe-eurasia':
      return 'U.S. Dept. of State - Europe & Eurasia';
    case 'us-state-gov-global-criminal-justice':
      return 'U.S. Dept. of State - Global Criminal Justice';
    case 'us-state-gov-global-health':
      return 'U.S. Dept. of State - Global Health';
    case 'us-state-gove-arms-control':
      return 'U.S. Dept. of State - Arms Control';
    case 'us-state-gov-international-law':
      return 'U.S. Dept. of State - International Law';
    case 'us-state-gov-international-organizations':
      return 'U.S. Dept. of State - International Organizations';
    case 'us-state-gov-nonproliferation':
      return 'U.S. Dept. of State - Nonproliferation';
    case 'us-state-gov-peace-corps':
      return 'U.S. Dept. of State - Peace Corps';
    case 'us-state-gov-political-military-affairs':
      return 'U.S. Dept. of State - Political-Military Affairs';
    case 'us-state-gov-population-refugees-migration':
      return 'U.S. Dept. of State - Population, Refugees, & Migration';
    case 'us-state-gov-public-diplomacy-global-affairs':
      return 'U.S. Dept. of State - Public Diplomacy & Global Affairs';
    case 'us-state-gov-south-central-asia':
      return 'U.S. Dept. of State - South & Central Asia';
    case 'us-state-gov-western-hemisphere':
      return 'U.S. Dept. of State - Western Hemisphere';
    case 'us-state-gov-arms-control':
      return 'U.S. Dept. of State - Arms Control';
    case 'us-commerce-gov-news':
      return 'U.S. Dept. of Commerce - News';
    case 'us-commerce-gov-blog':
      return 'U.S. Dept. of Commerce Blog';
    case 'us-commerce-gov-commerce-blog':
      return 'U.S. Dept. of Commerce - Commerce Blog';
    case 'us-commerce-gov-commerce-this-week':
      return 'U.S. Dept. of Commerce - Commerce This Week';
    case 'us-commerce-gov-press-release':
      return 'U.S. Dept. of Commerce - Press Release';
    case 'us-commerce-gov-testimony':
      return 'U.S. Dept. of Commerce - Testimony';
    case 'us-commerce-gov-speeches':
      return 'U.S. Dept. of Commerce - Speeches';
    case 'us-commerce-gov-statements':
      return 'U.S. Dept. of Commerce - Statements';
    case 'ed-gov-press-releases':
      return 'U.S. Dept. of Ed - Press Releases';
    case 'ed-gov-speeches':
      return 'U.S. Dept. of Ed - Speeches';
    case 'ed-gov-testimony':
      return 'U.S. Dept. of Ed - Testimony';
    case 'ed-gov-press-office':
      return 'U.S. Dept. of Ed - Press Office';
    case 'ed-gov-media-advisories':
      return 'U.S. Dept. of Ed - Media Advisories';
    case 'ed-gov-letters-secretary':
      return 'U.S. Dept. of Ed - Letters from the Secretary';
    case 'ed-gov-letters-general-counsel':
      return 'U.S. Dept. of Ed - Letters from the General Counsel';
    case 'ed-gov-letters-assistant-secretary':
      return 'U.S. Dept. of Ed - Letters from the Assistant Secretary';
    case 'ed-gov-letters-deputy-secretary':
      return 'U.S. Dept. of Ed - Letters from the Deputy Secretary';
    case 'ed-gov-letters-under-secretary':
      return 'U.S. Dept. of Ed - Letters from the Under Secretary';
    case 'ed-gov-letters-chief-staff':
      return 'U.S. Dept. of Ed - Letters from the Chief of Staff';
    case 'ed-gov-letters-communications':
      return 'U.S. Dept. of Ed - Letters from the Communications Office';
    case 'ed-gov-letters-press-secretary':
      return 'U.S. Dept. of Ed - Letters from the Press Secretary';
    case 'ed-gov-letters-press-office':
      return 'U.S. Dept. of Ed - Letters from the Press Office';
    default:
      return '';
  }
}

export function reactionSection(post) {
  if (!post) return null;
  return (
    <Box className="info__card__footer">
      <Box display="flex" gap="4px">
        <Box
          is="img"
          src={helpIcon}
          width="20px"
          title={`${getScoresFormatted(
            post.metadata.veracityScore,
            post.metadata.sentimentScore,
            post.metadata.politicalScore,
          )}`}
        />

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          gap="12px"
        >
          <Text className="score_text">
            Veracity: &nbsp;
            {getVeracityString(post.metadata.veracityScore)}
          </Text>
          <Text className="score_text">
            Sentiment: &nbsp;
            {getSentimentString(post.metadata.sentimentScore)}
          </Text>
          <Text className="score_text">
            Lean: &nbsp;
            {getPoliticalLeanString(post.metadata.politicalScore)}
          </Text>
        </Box>
      </Box>
    </Box>
  );
}

export function showShares(post) {
  if (!post) return null;

  return (
    <SocialShare
      url={window.location.href + 'image?id=' + post.image_url}
      title={post.title}
      decription={post.description}
      hashtags={[post.category]}
    />
  );
  /*
    return (
      <Box className="reaction__score">
        <Box is="img" src={shareIcon} onClick="handleShareClick" width="50%" />
        <Text className="headline__reaction__sub__text">
          {post.metadata.shares}
        </Text>
      </Box>
    );
    */
}

export function showComments(post) {
  if (!post) return null;
  return (
    <Box className="reaction__score">
      <Box is="img" src={commentIcon} width="50%" />
      <Text className="headline__reaction__sub__text">
        {post.metadata.comments}
      </Text>
    </Box>
  );
}

export function showLikesDislikes(post) {
  if (!post) return null;
  return (
    <Box display="flex" gap="4px">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flexStart"
        gap="6px"
      >
        <Box is="img" src={thumbsUpIcon} width="15%" />
        <Text className="headline__reaction__sub__text">
          {post.metadata.likes}
        </Text>

        <Box is="img" src={thumbsDownIcon} width="15%" />
        <Text className="headline__reaction__sub__text">
          {post.metadata.dislikes}
        </Text>
      </Box>
    </Box>
  );
}

export function showInfoFooter(post) {
  if (!post) return null;
  return (
    <Box className="info__card__footer">
      <Box display="flex" flexDirection="row" gap="2em">
        {showComments(post)}
        {showShares(post)}
      </Box>
      <Box className="reaction__score">
        <Link to={`bookmark-details-page/${post.id}`}>
          <Box is="img" src={bookmarkIcon} width="80%" />
          <Text className="post__reaction__sub__text text__alignment"></Text>
        </Link>
      </Box>
    </Box>
  );
}

/*
// Export the functions
module.exports = {
  capitalizeFirstLetter,
  isEmptyString,
  reverseString,
  countSubstringOccurrences,
    truncateString,
    truncateNumber,
    daysAgo,
    getVeracityString,
    getSentimentString,
    getPoliticalLeanString,
    getChannelString,
    getCategoryAndTopicsString,
    getScoresFormatted,
    renderMetadata
};
*/
