import React, { Fragment } from 'react';
import BlogHeader from '../../components/FeedHeader/BlogHeader';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import NewsFeedLayout from '../../layouts/NewsFeedLayout';
import ScienceHeadlineCards from '../../components/HeadlineCard/HeadlineCards';
import FirstColumnAd from '../../components/ColumnAd/FirstColumnAd';
import { useScience } from './useScience';
import SpinnerMini from '../../components/Spinner/SpinnerMini';
import ProfileWidget from '../../components/SidebarProfile/ProfileWidget';
import WatchingSection from '../../components/SidebarProfile/WatchingSection';

const ScienceBlogPage = () => {
  const headlineCards = <ScienceHeadlineCards />;

  const { isLoading } = useScience();

  if (isLoading)
    return (
      <Fragment>
        <BlogHeader hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
        <SpinnerMini />
      </Fragment>
    );

  return (
    <Fragment>
      <BlogHeader hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
      <NewsFeedLayout
        ProfileSection={<ProfileWidget />}
        WatchingSection={<WatchingSection />}
        firstColumnAd={<FirstColumnAd />}
        gridItems={headlineCards}
      />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default ScienceBlogPage;
