import React from 'react';
import avatar from '../../images/bert.bits 2.png';
import s from './ProfileWidget.module.scss';
import { Box, Text } from '@deposits/ui-kit-react';

const ProfileWidget = () => {
  // const moveBack = useMoveBack();

  return (
    <Box>
      {/* <div className="nav__section" onClick={moveBack}>
        <Box is="img" src={arrowBackIcon} width="20px" />
        <Box>
          <Text className="nav__main__text__font">Back</Text>
        </Box>
      </div> */}
      <div className={s.box}>
        <p className={s.box__heading}>Profile</p>
        <img className={s.box__image} src={avatar} alt="user avatar" />
        <div className={s.box__wrapper}>
          <p className={s.box__wrapper__name}>Bert Nieves</p>
          <p className={s.box__wrapper__username}>@bert.nieves</p>
          <p className={s.box__wrapper__location}>Philadelphia Region</p>
        </div>
        <p className={s.box__interests}>
          Interests in #brains #science #ai #physics #cosmology
        </p>
        <p className={s.box__followStatus}>150 followers | Following 150</p>
      </div>
    </Box>
  );
};

export default ProfileWidget;
