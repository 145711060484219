import { create } from 'zustand';

const usePostQueryStore = create((set) => ({
  postQuery: {},
  newsFeedStyle: 'double',
  activeCategoryLeft: 'science',
  activeCategoryRight: 'science',
  activeTopicLeft: '',
  activeTopicRight: '',

  setSearchText: (searchText) => set(() => ({ postQuery: { searchText } })),
  setActiveCategoryLeft: (category) => set({ activeCategoryLeft: category }),
  setActiveCategoryRight: (category) => set({ activeCategoryRight: category }),
  setActiveTopicLeft: (topic) => set({ activeTopicLeft: topic }),
  setActiveTopicRight: (topic) => set({ activeTopicRight: topic }),
  setNewsFeedStyle: (style) => set({ newsFeedStyle: style }),
}));

export default usePostQueryStore;
