import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import HeroTopics from '../../components/hero/heroTopics';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import Subscribe from '../../components/Subscribe/Subscribe';
import { useDynamicPosts } from '../../hooks/useDynamicPosts';
import TrendingTopics from '../../components/TrendingNews/TrendingTopics';

const HomeTopics = () => {
 
  const { isLoading } = useDynamicPosts();

  if (isLoading)
    return (
      <Fragment>
        <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
        <HeroTopics />
      </Fragment>
    );

  return (
    <Fragment>
      <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
      <HeroTopics />
      <TrendingTopics />
      <Subscribe />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomeTopics;
